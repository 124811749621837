import { AppMeta } from '@/model/Android';
import { StorageService } from './StorageService';
import { callAndroidAsync } from './WebviewService';
import { FRAUDTYPES } from '@/constants/Android';

export const AndroidService = {
  isNoWindow:
    typeof window === 'undefined' || typeof window.Android === 'undefined',

  getProfileId() {
    if (this.isNoWindow) return;
    return window.Android.getProfileId();
  },
  getPackageName() {
    if (this.isNoWindow) return;
    return window.Android.getPackageName();
  },
  /**
   *
   * @returns {AppMeta}
   */
  getAppMeta(pkg) {
    if (this.isNoWindow) return;
    let data = {};
    try {
      data = JSON.parse(
        window.Android.getAppMeta(pkg || this.getPackageName())
      );
    } catch (error) {
      data = {};
    }
    return AppMeta.from(data);
  },

  /**
   *
   * @returns  {{
   * android_version: string,
   * api_level: number,
   * brand: string,
   * up_time: number,
   * manufacturer: string,
   * model: string
   * }}
   */
  getDeviceMeta() {
    if (this.isNoWindow) return;
    const res = window.Android.getDeviceMeta();
    if (!res) return null;
    return JSON.parse(res);
  },
  /**

  /**
   *
   * @returns {{
   * device_model: string,
   * android_id: string,
   * ga_id: string,
   * gsf_id: string,
   * drm_id: string,
   * app_set_id: string,
   * package_id: string
   * }}
   *
   */
  getDeviceIdentifiers() {
    if (this.isNoWindow) return;
    let data = {};
    try {
      data = JSON.parse(window.Android.getDeviceIdentifiers());
    } catch (error) {
      data = {};
    }
    return data;
  },
  /**
   *
   * @param {string} sdk
   * @param {string} key
   * @param {string} userId
   * @returns
   */
  initOfferwall(sdk, key, userId) {
    if (this.isNoWindow) return;
    return window.Android.initOfferwall(sdk, key, userId);
  },

  /**
   *
   * @param {string} sdk
   * @returns
   */
  launchOfferwall(sdk) {
    if (this.isNoWindow) return;
    return window.Android.launchOfferwall(sdk);
  },

  getAppForegroundTime(pkg, startTime, endTime) {
    // console.log(pkg, startTime, endTime);
    if (this.isNoWindow) return;
    return window.Android.getAppForegroundTime(pkg, startTime, endTime);
  },

  getInstalledPackages(includeSystemAppPackages) {
    if (this.isNoWindow) return;
    let data = {};
    try {
      data = JSON.parse(
        window.Android.getInstalledPackages(includeSystemAppPackages)
      );
    } catch (error) {
      data = {};
    }
    return data;
  },
  getAppUsageData() {
    if (this.isNoWindow) return;
    let data = {};
    try {
      data = JSON.parse(window.Android.getAppUsageData());
    } catch (error) {
      data = {};
    }
    return data;
  },
  encrypt(userId, ...args) {
    if (this.isNoWindow) return;
    return window.Android.encrypt(userId, ...args);
  },

  isAppInstalled(...args) {
    if (this.isNoWindow) return;
    return window.Android.isAppInstalled(...args);
  },

  openUrlInBrowser(...args) {
    if (this.isNoWindow) return;
    return window.Android.launchUrl(...args);
  },

  showToast(...args) {
    if (this.isNoWindow) return;
    return window.Android.showToast(...args); //LANDSCAPE, PORTRAIT
  },
  /**
   *
   * @param {string} pkg
   * @returns
   */
  launchApp(pkg) {
    if (this.isNoWindow) return;
    return window.Android.launchApp(pkg);
  },
  logEvent(id, payload = {}) {
    if (this.isNoWindow) return;
    try {
      const finalPayload = !payload
        ? payload
        : typeof payload === 'string'
        ? payload
        : JSON.stringify(payload);
      const args = [id, finalPayload];
      return window.Android.logEvent(...args);
    } catch (err) {
      console.error(err);
    }
  },
  /**
   *
   * @param {string} key
   * @param {string} val
   * @returns
   */
  addUserParamsAnalytics(key, val) {
    if (this.isNoWindow) return;
    try {
      return window.Android.addUserParamsAnalytics(key, val);
    } catch (err) {
      console.error(err);
    }
  },

  isDebugEnabled(...args) {
    if (this.isNoWindow) return;
    try {
      return window.Android.isDebugEnabled(...args);
    } catch (err) {
      console.error(err);
    }
  },

  /**
   *
   * @param {string[]} frauds
   * @returns
   */

  async performFraudChecks(frauds) {
    if (this.isNoWindow) return;
    try {
      const res = await callAndroidAsync('performFraudChecks', {
        check_list: frauds,
      });

      if (!res) return null;

      const activeFrauds = JSON.parse(res);

      const fraudsArr = Object.keys(activeFrauds)
        ?.filter(d => d === FRAUDTYPES.vpn)
        .map(d => {
          const isBlacklistedType = d === FRAUDTYPES.blacklistedApp;
          return {
            type: d,
            hasFraud: isBlacklistedType
              ? JSON.parse(activeFrauds[d]).length
              : activeFrauds[d],
            data: isBlacklistedType
              ? JSON.parse(activeFrauds[d]).map(
                  pkg => AndroidService.getAppMeta(pkg).name
                )
              : undefined,
          };
        })
        .filter(d => d.hasFraud);
      return fraudsArr;
    } catch (error) {
      return [];
    }
  },

  shareText(subject = '', message = '') {
    if (this.isNoWindow) return;
    return window.Android?.shareText(subject, message);
  },

  /**
   *
   * @param {string} permission
   * @returns
   */

  checkPermission(permission) {
    if (this.isNoWindow) return;
    const perm = window.Android?.checkPermission(permission);
    return perm === 'GRANTED';
  },

  /**
   *
   * @param {string} permission
   * @returns
   */

  getPermission(permission) {
    if (this.isNoWindow) return;
    return window.Android?.getPermission(permission);
  },

  /**
   *
   * @param {string} permission
   * @returns
   */

  openAppSettings(permission) {
    if (this.isNoWindow) return;
    return window.Android?.openAppSettings(this.getPackageName(), permission);
  },

  getDeepLink() {
    if (this.isNoWindow) return;
    return window.Android?.getDeepLink();
  },
  /**
   *
   * @returns {{ deep_link: "string",
   * notification: any }}
   */
  getIntentData() {
    if (this.isNoWindow) return;
    let data = {};
    try {
      data = JSON.parse(window.Android.getIntentData());
    } catch (error) {
      data = {};
    }
    return data;
  },

  async signInGoogle() {
    if (this.isNoWindow) return;
    return new Promise((resolve, reject) => {
      window.Android?.signIn('GOOGLE');
      window.signInGoogleCallback = (success, data) => {
        resolve({ success, data });
      };
    });
  },
  async signOut() {
    if (this.isNoWindow) return;
    return new Promise((resolve, reject) => {
      window.Android?.signOut();
      window.signOutGoogleCallback = success => {
        success ? resolve() : reject();
      };
    });
  },
  runAsync(...args) {
    if (this.isNoWindow) return;
    return window.Android?.runAsync(...args);
  },
  runAsyncResult(...args) {
    if (this.isNoWindow) return;
    return window.Android?.runAsyncResult(...args);
  },
  async generateDynamicLink({ url, dynamicLink }) {
    if (this.isNoWindow) return;
    const res = await callAndroidAsync('generateDynamicLink', {
      url,
      dynamic_link_base_url: dynamicLink,
    });
    return res;
  },
  async getInstallReferrerData() {
    if (this.isNoWindow) return;
    const res = await callAndroidAsync('getInstallReferrerData', null);
    return res;
  },
  async getAppUsageData({ startTime, endTime }) {
    if (this.isNoWindow) return;
    const res = await callAndroidAsync('getAppUsageData', {
      start_time: startTime,
      end_time: endTime,
    });
    return res;
  },
  async getUpdatedFCMToken() {
    if (this.isNoWindow) return;
    const res = await callAndroidAsync('getFCMToken');
    return res;
  },
  async clearBrowserHistory() {
    if (this.isNoWindow) return;
    return (
      window.Android?.clearBrowserHistory &&
      window.Android?.clearBrowserHistory()
    );
  },
  async closeApp() {
    if (this.isNoWindow) return;
    return window.Android?.closeApp && window.Android?.closeApp();
  },
  async registerLifecycleListener(funcName) {
    if (this.isNoWindow) return;
    return (
      typeof window.Android?.registerLifecycleListener === 'function' &&
      window.Android?.registerLifecycleListener(funcName)
    );
  },
  async unregisterLifecycleListener(funcName) {
    if (this.isNoWindow) return;
    typeof window.Android?.unregisterLifecycleListener === 'function' &&
      window.Android?.unregisterLifecycleListener(funcName);
  },
  async updateSwipeRefreshState(value) {
    if (this.isNoWindow) return;
    typeof window.Android?.updateSwipeRefreshState === 'function' &&
      window.Android?.updateSwipeRefreshState(value);
  },
  async launchUrlCCT(value) {
    if (this.isNoWindow) return;
    if (typeof window.Android?.launchUrlCCT === 'function') {
      window.Android?.launchUrlCCT(value);
    }
  },
};
